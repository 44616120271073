import {
    getModule,
    Module,
    VuexModule,
    MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { SessionStorage } from "@/store/session-storage";
import { SearchResponse, SearchItem } from "@/api/profile_item_csv/response";
import { SearchListRequest } from "@/api/profile_item_csv/request";
import * as ProfileItemCsvAPI from "@/api/profile_item_csv";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "profile_item_csv/list";

/**
 * ユーザ一覧のデコレーター
 */
export class ProfileItemCsvItem {
    constructor(private _item: SearchItem) {
    }
    /** ユーザーID */
    get userId() {
        return this._item.userId;
    }

    /** ユーザー名 */
    get userName() {
        return this._item.userName;
    }

    /** 誕生日 */
    get birthday() {
        return this._item.birthday;
    }

    /** 性別 */
    get gender() {
        switch (this._item.gender) {
            case 1:
                return "男性";
            case 2:
                return "女性";
            default:
                return "不明";
        }
    }
}

/**
 * ユーザー情報検索（プロフィール追加項目指定）API（/get-user-by-add-profile-item）のレスポンスを管理するストアモジュール
 */
@Module({dynamic: true, store, name: MODULE_NAME, namespaced: true})
class List extends VuexModule {
    // state
    searchResponse: SearchResponse = {} as SearchResponse;

    // getters
    get isSuccess() {
        return isSuccess(this.searchResponse);
    }

    get getMessage() {
        return this.searchResponse.message;
    }

    get getItems() {
        if (this.searchResponse.results) {
            return (this.searchResponse.results.items || []).map(
                i => new ProfileItemCsvItem(i)
            );
        } else {
            return [];
        }
    }

    // MutationActions
    @MutationAction
    async search(searchListRequest: SearchListRequest) {
        const searchResponse = await ProfileItemCsvAPI.list(searchListRequest);
        SessionStorage.setObject(MODULE_NAME, searchListRequest);
        return {
            searchResponse
        };
    }

    @MutationAction
    async clearResponse() {
        return {
            searchResponse: {} as SearchResponse
        };
    }
}

export default getModule(List);
