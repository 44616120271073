import service from "@/api/service";
import {GetRequest, SearchListRequest,} from "@/api/profile_item_csv/request";
import {GetResponse, SearchResponse} from "@/api/profile_item_csv/response";

/**
 * ユーザー情報検索（プロフィール追加項目指定）APIをコールします。
 *
 * @param searchListRequest ユーザー情報検索（プロフィール追加項目指定）のリクエストボディ
 * @return SearchResponse
 */
export async function list(searchListRequest: SearchListRequest) {
    const response = await service.post("/get-user-by-add-profile-item", searchListRequest);
    return response.data as SearchResponse;
}

/**
 * プロフィール追加項目CSV読み込みボタン情報取得APIをコールします。
 *
 * @param getRequest プロフィール追加項目CSV読み込みボタン情報取得Aのリクエストボディ
 * @return GetResponse
 */
export async function getItem(getRequest: GetRequest) {
    const response = await service.post("/get-add-profile-items-csv-button-info", getRequest);
    return response.data as GetResponse;
}