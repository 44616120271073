import {
    getModule,
    Module,
    VuexModule,
    MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { SessionStorage } from "@/store/session-storage";
import {GetResponse} from "@/api/profile_item_csv/response";
import {GetRequest} from "@/api/profile_item_csv/request";
import * as ProfileItemCsvAPI from "@/api/profile_item_csv";

const MODULE_NAME = "profile_item_csv/get";

/**
 * プロフィール追加項目CSV読み込みボタン情報取得API（/get-add-profile-items-csv-button-info）のレスポンスを管理するストアモジュール
 */
@Module({dynamic: true, store, name: MODULE_NAME, namespaced: true})
class Get extends VuexModule {
    // state
    getResponse: GetResponse = {} as GetResponse;

    get getMessage() {
        return this.getResponse.message;
    }

    /** プロフィール項目ID */
    get profileItemId() {
        return this.getResponse.profileItemId;
    }

    /** ボタン名 */
    get buttonName() {
        return this.getResponse.buttonName;
    }

    /** ボタン表示フラグ */
    get buttonDisplayFlg() {
        return this.getResponse.buttonDisplayFlg;
    }

    /** CSV読み込み上限数 */
    get csvRecordLimit() {
        return this.getResponse.csvRecordLimit;
    }

    // MutationActions
    @MutationAction
    async GetAddProfileItem(getRequest: GetRequest) {
        const getResponse = await ProfileItemCsvAPI.getItem(getRequest);
        SessionStorage.setObject(MODULE_NAME, getResponse);
        return {
            getResponse
        };
    }

    @MutationAction
    async clearResponse() {
        return {
            getResponse: {} as GetResponse
        };
    }
}

export default getModule(Get);
