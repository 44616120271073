import { Component, Vue, Prop } from "vue-property-decorator";
import WindowSizeChecker from "@/utils/window-size-checker";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { SORT_ORDER_DESC, TableOptions } from "@/api/request";
import { DetailRequest } from "@/api/coupon/request";
import {
  DetailPoint,
  DetailProfile,
  DetailQuestion,
  DetailResult,
  DetailStamp,
  DetailTicket
} from "@/api/push/response";
import UserSearch, { ListItem } from "@/store/user-push/search";
import UserList from "@/store/user-push/list";
import ProfileItemCsvList from "@/store/profile_item_csv/list";
import ProfileItemCsvGetItem from "@/store/profile_item_csv/get";
import CouponDetail from "@/store/coupon/detail";
import Flash, { ErrorAlert } from "@/store/common/flash";
import { SearchRequest } from "@/api/user/request";
import { RegisterRequest } from "@/api/push/request";

@Component
export default class MixinNewEdit extends Vue {
  @Prop({ type: String, required: false })
  id?: string | null;
  @Prop({ type: Object, required: false })
  pushDetail?: DetailResult | null;

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "", value: "custom" },
    { text: "ユーザーID", value: "userId" },
    { text: "名前", value: "userName" },
    { text: "性別", value: "gender" },
    { text: "生年月日", value: "birthday" }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  editorOption = {
    modules: {
      toolbar: [
        [{ color: [] }, { background: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ size: ["small", false, "large", "huge"] }],
        [{ align: [] }],
        ["link", "image"],
        ["clean"]
      ]
    }
  };

  // 予約時間（時）のselect
  hours = [...Array(24).keys()].map(n => {
    const val = ("00" + n).slice(-2);
    return { text: val, value: val };
  });

  // 予約時間（分）のselect
  minutes = [...Array(6).keys()].map(n => {
    const val = ("00" + n * 10).slice(-2);
    return { text: val, value: val };
  });

  // ローディングステータス
  isLoading = false;

  inputParams = {
    /** プッシュ通知ID */
    id: null as number | null,
    /** プッシュ通知タイプ */
    pushType: 1 as number,
    /** コンテンツタイプ */
    contentsType: null as string | null,
    /** コンテンツID */
    contentsId: null as number | null,
    /** 性別絞り込み選択 */
    genderFlg: null as number | null,
    /** 年代絞り込み選択 */
    ageFlg: null as number | null,
    /** 誕生日絞り込み選択 */
    birthdayFlg: null as number | null,
    /** プロフィール追加項目絞り込み選択 */
    profileFlg: null as number | null,
    /** プロフィール未登録車 */
    profileUndefined: null as number | null,
    /** 位置情報選択 */
    areaFlg: null as number | null,
    /** スタンプカード利用状況 */
    stampFlg: null as number | null,
    /** ポイントカード利用状況 */
    pointFlg: null as number | null,
    /** フォロー店舗選択 */
    followFlg: null as number | null,
    /** デジタルチケット利用フラグ */
    paidTicketFlg: null as number | null,
    /** デジタルチケット購入者選択 */
    ticketFlg: null as number | null,
    /** 性別選択内容 */
    gender: null as number | null,
    /** 年代選択内容 */
    age: null as number[] | null,
    /** 誕生日入力内容 */
    birthdayFrom: null as string | null,
    /** 誕生日入力内容 */
    birthdayEnd: null as string | null,
    /** 追加項目配列 */
    profile: [] as DetailProfile[] | null,
    /** 位置情報項目選択内容 */
    area: null as number | null,
    /** スタンプ条件配列 */
    stamp: {} as DetailStamp | null,
    /** ポイント条件配列 */
    point: {} as DetailPoint | null,
    /** フォロー店舗ID配列 */
    favoriteShopIds: null as number[] | null,
    /** デジタルチケット条件配列 */
    ticket: {} as DetailTicket | null,
    /** 特定配信対象ユーザーID配列 */
    userIds: null as number[] | null,
    /** ポイント付与期間指定 */
    pointTermFlg: null as number | null,
    /** 有効期限 */
    pointTermStart: null as string | null,
    /** 有効期限 */
    pointTermEnd: null as string | null,
    /** プッシュアンケートURL利用フラグ */
    pushLinkFlg: null as number | null,
    /** アンケートURL */
    linkUrl: null as string | null,
    /** エンケート設定配列 */
    question: null as DetailQuestion[] | null,
    /** タイトル */
    title: null as string | null,
    /** 本文 */
    comment: null as string | null,
    /** 本文（画像） */
    image: null as string | null,
    /** 配信除外ユーザID配列 */
    leaveUsers: null as string[] | null,
    /** 配信時間設定 */
    sendTimeType: 1 as number,
    /** 配信日 */
    sendDate: null as string | null,
    /** 配信時間 */
    //NEW_DEV-1925 cyber start
    sendTime: "00:00" as string | null,
    //NEW_DEV-1925 cyber end
    /** 固定表示 */
    fixedFlg: 0 as number,
    /** プレビュー（0:保存, 1:プレビュー）*/
    isPreview: 0 as number,

    snsPostLinkFlg: 0 as 0 | 1,

    twitterPostFlgExt: 0 as 0 | 1, /* m_shop_extension.twitter_post_flg */
    facebookPostFlgExt: 0 as 0 | 1, /* m_shop_extension.facebook_post_flg */
    instagramPostFlgExt: 0 as 0 | 1, /* m_shop_extension.instagram_post_flg */
    googleBizProfPostFlgExt: 0 as 0 | 1, /* m_shop_extension.google_biz_post_flg */
    twitterFlgExt: 0 as 0 | 1, /* m_shop_extension.twitter_flg */
    facebookFlgExt: 0 as 0 | 1, /* m_shop_extension.facebook_flg */
    instagramFlgExt: 0 as 0 | 1, /* m_shop_extension.instagram_flg */
    googleBizProfFlgExt: 0 as 0 | 1, /* m_shop_extension.google_biz_flg */

    twitterFlag: 0 as 0 | 1,
    facebookFlag: 0 as 0 | 1,
    instagramFlag: 0 as 0 | 1,
    googleBizFlag: 0 as 0 | 1,
    twitterMessage: '',
    facebookMessage: '',
    instagramMessage: '',
    googleBizMessage: '',
  } as DetailResult;
  inputOptions = { userName: null as string | null };

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions: TableOptions = {
    page: 1,
    displayCount: 20,
    sortKey: "userId",
    sortOrder: SORT_ORDER_DESC
  };

  // 配信内容設定
  activeSubTab = 0;
  eventId = null as number | null;
  couponId = null as number | null;

  /**
   * クエリパラメータから店舗IDを返却する（必須）
   */
  get shopId() {
    return this.$route.query.shopId as string;
  }

  /**
   * クエリパラメータから店舗名を返却する（必須）
   */
  get shopName() {
    if (this.$route.query.shopName) {
      return this.$route.query.shopName;
    } else {
      return "";
    }
  }

  // NEW_DEV-1437 cyber start
  /**
   * クエリパラメータからフォロー状態無視フラグを返却する
   */
  get ignoreFollowFlg() {
    if (this.$route.query.ignoreFollowFlg) {
      return this.$route.query.ignoreFollowFlg;
    } else {
      return "";
    }
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return UserSearch.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return UserSearch.getTotalCount;
  }

  /**
   * プロフィール項目ID
   */
  get profileItemId() {
    return ProfileItemCsvGetItem.profileItemId;
  }

  /**
   * ボタン名
   */
  get buttonName() {
    return ProfileItemCsvGetItem.buttonName;
  }

  /**
   * ボタン表示フラグ
   */
  get buttonDisplayFlg() {
    return ProfileItemCsvGetItem.buttonDisplayFlg;
  }

  /**
   * CSV読み込み上限数
   */
  get csvRecordLimit() {
    return ProfileItemCsvGetItem.csvRecordLimit;
  }

  get sendHour() {
    if (this.inputParams.sendTime) {
      const val = this.inputParams.sendTime.split(":");
      if (val.length >= 1) {
        return val[0];
      } else {
        return "00";
      }
    } else {
      return "00";
    }
  }

  get sendMinute() {
    if (this.inputParams.sendTime) {
      const val = this.inputParams.sendTime.split(":");
      if (val.length >= 2) {
        return val[1];
      } else {
        return "00";
      }
    } else {
      return "00";
    }
  }

  set sendHour(hour) {
    if (this.inputParams.sendTime) {
      const val = this.inputParams.sendTime.split(":");
      if (val.length >= 2) {
        this.inputParams.sendTime = `${hour}:${val[1]}`;
      } else {
        this.inputParams.sendTime = `${hour}:00`;
      }
    }
  }

  set sendMinute(min) {
    if (this.inputParams.sendTime) {
      const val = this.inputParams.sendTime.split(":");
      if (val.length >= 1) {
        this.inputParams.sendTime = `${val[0]}:${min}`;
      } else {
        this.inputParams.sendTime = `00:${min}`;
      }
    }
  }

  get isEdit() {
    return !!this.id;
  }

  get isTabletAndPC() {
    return WindowSizeChecker.isTabletAndPC(this);
  }

  get isPhoneAndTablet() {
    return WindowSizeChecker.isPhoneAndTablet(this);
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    if (this.tableOptions.sortKey == null) {
      this.tableOptions.sortKey = "userId";
    }
    await this.search();
  }

  /**
   * DatePickerの日付が変更された際のコールバック
   */
  changeDateCallback(date: string) {
    this.inputParams.sendDate = date;
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    await Flash.clear();
    await this.search();
  }

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async actionClickCallback(item: ListItem) {}

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async editClickCallback(item: ListItem) {}

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  deleteClickCallback(item: ListItem) {}

  /**
   * 検索処理
   */
  async search() {
    this.isLoading = true;
    const request = { ...this.createSearchRequest(), ...this.tableOptions };
    await UserSearch.search(request);
    this.isLoading = false;
    if (!UserSearch.isSuccess) {
      await Flash.setErrorNow({
        message: UserSearch.getMessage,
        showReloadButton: false
      } as ErrorAlert);
      return;
    }
  }

  async searchList(userIds: string[]) {
    this.isLoading = true;
    await UserList.search({
      searchShopId: Number(this.shopId),
      searchUserIds: userIds
    });
    this.isLoading = false;
    if (!UserList.isSuccess) {
      await Flash.setErrorNow({
        message: UserList.getMessage,
        showReloadButton: false
      } as ErrorAlert);
      return;
    }
  }

  async GetAddProfileItem() {
    this.isLoading = true;
    await ProfileItemCsvGetItem.GetAddProfileItem({
      shopId: Number(this.shopId),
    });
    this.isLoading = false;
    if (!ProfileItemCsvGetItem.isSuccess) {
      await Flash.setErrorNow({
        message: ProfileItemCsvGetItem.getMessage,
        showReloadButton: false
      } as ErrorAlert);
      return;
    }
  }

  async searchProfileItemCsvList(profileItemCsvIds: string[]) {
    this.isLoading = true;
    await ProfileItemCsvList.search({
      shopId: Number(this.shopId),
      profileItemId: Number(this.profileItemId),
      profileValues: profileItemCsvIds
    });
    this.isLoading = false;
    if (!ProfileItemCsvList.isSuccess) {
      await Flash.setErrorNow({
        message: ProfileItemCsvList.getMessage,
        showReloadButton: false
      } as ErrorAlert);
      return;
    }
  }

  async checkCouponDate() {
    await CouponDetail.detail({
      id: Number(this.couponId),
      shopId: Number(this.shopId)
    } as DetailRequest);

    const detail = { ...CouponDetail.getDetail };
    var couponStartDate = detail.startDate;
    var couponPublishStartDate = detail.publishStartDate;

    // すぐに配信
    if (this.inputParams.sendTimeType == 1) {
      var today = new Date();
      if (couponPublishStartDate) {
        if (today < Date.parse(couponPublishStartDate)) {
          return 'クーポンの事前表示日より前に配信することはできません。';
        }
      } else {
        if (today < Date.parse(couponStartDate)) {
          return 'クーポンの開始日より前に配信することはできません。';
        }
      }
    // 配信予約
    } else {
      if (couponPublishStartDate) {
        if (Date.parse(this.inputParams.sendDate) < Date.parse(couponPublishStartDate)) {
          return 'クーポンの事前表示日より前に配信することはできません。';
        }
      } else {
        if (Date.parse(this.inputParams.sendDate) < Date.parse(couponStartDate)) {
          return 'クーポンの開始日より前に配信することはできません。';
        }
      }
    }
    return null;
  }

  protected syncBaseParams(pushDetail: DetailResult) {
    this.inputParams.sendTimeType = pushDetail.sendTimeType;
    this.inputParams.sendDate = pushDetail.sendDate;
    this.inputParams.sendTime = pushDetail.sendTime;
    if (this.inputParams.sendTime) {
      const val = this.inputParams.sendTime.split(":");
      if (val.length >= 2) {
        this.inputParams.sendTime = `${val[0]}:${val[1]}`;
      }
    }

    this.inputParams.fixedFlg = pushDetail.fixedFlg;
    this.inputParams.title = pushDetail.title;
    this.inputParams.comment = pushDetail.comment;
    this.inputParams.twitterFlag = pushDetail.twitterFlag;
    this.inputParams.facebookFlag = pushDetail.facebookFlag;
    this.inputParams.instagramFlag = pushDetail.instagramFlag;
    this.inputParams.googleBizFlag = pushDetail.googleBizFlag;
    this.inputParams.twitterMessage = pushDetail.twitterMessage;
    this.inputParams.facebookMessage = pushDetail.facebookMessage;
    this.inputParams.instagramMessage = pushDetail.instagramMessage;
    this.inputParams.googleBizMessage = pushDetail.googleBizMessage;
    this.inputParams.snsPostLinkFlg = pushDetail.snsPostLinkFlg;
  }

  protected syncSendContentParams(pushDetail: DetailResult) {
    switch (pushDetail.contentsType) {
      case "1":
        this.activeSubTab = 0;
        this.eventId = pushDetail.contentsId;
        break;
      case "2":
        this.activeSubTab = 1;
        this.couponId = pushDetail.contentsId;
        break;
      case "5":
        this.activeSubTab = 2;
        break;
    }
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  protected createSearchRequest() {
    const request = { searchShopId: Number(this.shopId) } as SearchRequest;
    if (this.inputOptions.userName) {
      request.userName = this.inputOptions.userName;
    } else {
      delete request.userName;
    }
    request.ignoreFollowFlg = String(this.ignoreFollowFlg);
    request.type = !this.$route.query.tab ? 0 : this.$route.query.tab;
    return request;
  }

  protected createRegisterCommonRequest() {
    const request = {
      shopId: Number(this.shopId),
      sendTimeType: this.inputParams.sendTimeType,
      fixedFlg: this.inputParams.fixedFlg,
      ignoreFollowFlg: Number(this.ignoreFollowFlg),
      // NEW_DEV-1437 cyber end
    } as RegisterRequest;
    if (this.inputParams.sendTimeType === 2) {
      request.sendDate = this.inputParams.sendDate;
      request.sendTime = this.inputParams.sendTime;
    }
    if (this.isEdit) {
      request.id = Number(this.id);
    }
    return request;
  }
}
